.teamContainer {
  padding-top: 11vh;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  h3 {
    font-family: "Josefin Sans", sans-serif;
    margin: 0;
    padding-top: 30px;
    font-size: 32px;
  }
  h4 {
    color: #585858;
    font-family: "Josefin Sans", sans-serif;
    line-height: 25px;
    font-size: 16px;
    font-weight: 300;
    text-align: justify;
  }
  h5 {
    border-bottom: 2px solid #293e31;
    width: 45px;
    margin: 0;
    color: #fff;
    margin-left: 2px;
    padding-top: 0px;
  }
  .teamContainer {
    margin-bottom: 60px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .teamList {
      width: 27%;
      img {
        height: 250px;
        margin-left: auto;
        margin-right: auto;
        display: block;
      }
      @media (max-width: 1050px) {
        img {
          height: 200px;
        }
      }
      h1 {
        font-family: "Josefin Sans", sans-serif;
        margin: 0;
        font-size: 20px;
        padding-top: 10px;
        text-align: center;
      }
      p {
        color: #585858;
        font-family: "Josefin Sans", sans-serif;
        font-size: 16px;
        text-align: center;
        // width: 250px;
        padding-bottom: 40px;
        line-height: 20px;
      }
      @media (max-width: 1050px) {
        h1 {
          padding-bottom: 24px;
        }
      }
    }
    @media(max-width:1100px){
      .teamList{
        width: 100%;
      }
    }
  }
}
