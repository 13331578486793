.bgConatiner {
  background-color: #ffd94c;
  .footerContainer {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-block-end: auto;
    justify-content: space-around;
    display: flex;
    flex-wrap: wrap;

    .navbar {
      li {
        list-style: none;
        font-family: "Josefin Sans", sans-serif;
        margin-top: 12px;
        font-size: 18px;
        color: #000;

        cursor: pointer;
      }
      li:hover {
        color: #fff;
      }
    }
    .socialLink {
      li {
        list-style: none;
        font-family: "Josefin Sans", sans-serif;
        margin-top: 12px;
        font-size: 18px;
        cursor: pointer;
        color: #000;
      }

      i {
        padding-right: 10px;
      }
      li:hover {
        color: #fff;
      }
    }
    .address {
      li {
        list-style: none;
        font-family: "Josefin Sans", sans-serif;
        margin-top: 12px;
        font-size: 18px;
        cursor: pointer;
      }
      i {
        padding-right: 10px;
      }
    }
    @media (max-width: 850px) {
      .address{
        display: none;
     }
   }
  }
  h5 {
    margin: 0;
    text-align: center;
    font-family: "Montserrat", sans-serif;
  }
  h4 {
    color: #fff;
    text-align: center;
    margin: 0;
    font-family: "Josefin Sans", sans-serif;
    font-weight: 300;
    padding-top: 10px;
    padding-bottom: 5px;
  }
  a {
    text-decoration: none;
  }
 
}
