.bestClass {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  display: block;
}
@media (max-width: 700px) {
  .bestClass {
    width: 100%;
  }
}
.imgClass {
}
.imgClass img {
  width: 170px;
  margin-top: 30px;
  margin-left: 60px;
}
@media (max-width: 700px) {
  .imgClass {
    display: none;
  }
}
.MobileView {
  display: none;
}
@media (max-width: 700px) {
  .MobileView {
    display: block;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    /* justify-content: space-between; */
    overflow-x: scroll;
  }
  .MobileView img {
    height: 350px;
  }
}
