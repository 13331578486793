.Navbar {
  h4 {
    cursor: pointer;
    font-size: 26px;
    color: #000000;
  }
}
.nav-menu {
  background-color: #ffd94c;
  width: 250px;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  right: -100%;
  transition: 850ms;
}
.nav-menu.active {
  right: 0;
  transition: 350ms;
}
.nav-text {
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 8px 0px 8px 16px;
  list-style: none;
  height: 50px;

}
.nav-text a {
  text-decoration: none;
  color: #000000;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 16px;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 200px;
  font-family: "Montserrat", sans-serif;
  border-bottom: 1px solid #fff;
}
.nav-menu-items {
  width: 100%;
}
.navbar-toogle {
  position: absolute;
  left: 20px;
  height: 40px;
  cursor: pointer;
  width: 40px;
  border-radius: 20px;
  color: #fff;
  font-size: 35px;
  display: flex;
  justify-content: space-around;
  text-align: center;
}
