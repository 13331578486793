.modalClass {
  height: 200px;
  background-color: #ffd94c;
  margin-top: 200px;
  border: 1px solid #fff;
  width: 350px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 15px;
  outline: none;
  .popupContainer {
    width: 350px;
    h5 {
      font-family: "Josefin Sans", sans-serif;
      font-size: 25px;
      margin: 0;
      color: #fff;
      cursor: pointer;
      margin-left: 320px;
      font-weight: 200;
    }
    h3 {
      font-family: "Josefin Sans", sans-serif;
      margin: 0;
      text-align: center;
    }
    img {
      height: 30px;
      margin-left: auto;
      margin-right: auto;
      display: block;
    }
    h4 {
      font-family: "Josefin Sans", sans-serif;
      color: #fff;
      text-align: center;
      margin: 0;
      padding-top: 20px;
      padding-bottom: 15px;
    }
    span {
      font-family: "Josefin Sans", sans-serif;
      margin-left: 20px;
    }
  }
}
